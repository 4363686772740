import { Page } from "../Layout/Page/Page";
import {
  Box,
  Divider,
  Grid,
  HStack,
  Select,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  VStack,
} from "@chakra-ui/react";
import { GoBack } from "../components/GoBack.tsx";
import { useTranslation } from "react-i18next";
import { NetWorthOverview } from "./NetWorthOverview/NetWorthOverview.tsx";
import { gql, useSuspenseQuery } from "@apollo/client";
import { NetWorthQuery, NetWorthQueryVariables } from "../generated/graphql.ts";
import { useAuth } from "../Auth/useAuth.ts";
import { Line } from "react-chartjs-2";
import {
  Chart,
  ChartData,
  ChartOptions,
  ScriptableContext,
  TooltipItem,
} from "chart.js";
import { capitalize, uniqBy } from "lodash";
import { Currency } from "../components/Currency.tsx";
import { ArrowDownIcon, ArrowUpIcon, InfoIcon } from "@chakra-ui/icons";
import { useMemo, useState } from "react";
import { endOfMonth, sub } from "date-fns";
import { useCurrency } from "../utils/useCurrency.ts";
import ContextualHelp from "../components/ContextualHelp.tsx";

const NetWorth = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [accountType, setAccountType] = useState<string | null>(null);
  const endDate = endOfMonth(sub(new Date(), { months: 1 }));
  const startDate = sub(endDate, { months: 6 });

  const { data } = useSuspenseQuery<NetWorthQuery, NetWorthQueryVariables>(
    QUERY,
    {
      variables: {
        user_id: user?.uid as string,
        account_type: accountType,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      },
      skip: !user?.uid,
    },
  );

  if (!data) {
    return null;
  }

  return (
    <Page maxW={"container.md"}>
      <VStack spacing={4} alignItems="stretch" width="100%" my={6}>
        <Box>
          <GoBack to={"/"} />
        </Box>
        <Box as={"header"}>
          <Text textStyle={"sectionTitle"}>{t("netWorth.title")}</Text>
          <Text textStyle={"sectionSubtitle"}>{t("netWorth.description")}</Text>
        </Box>
        <Grid gap={4} templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}>
          <NetworthStat
            current={data?.networth_stats[0].current || 0}
            gain={data?.networth_stats[0].gain || 0}
            startDate={sub(new Date(), { months: 6 })}
            endDate={
              new Date(
                data.networth_history[data.networth_history.length - 1].date,
              )
            }
          />
          <IndependenceRevenueStat
            revenue={
              data?.networth_stats[0].financial_independence_revenue || 0
            }
            target={data.avg_monthly_expenses.aggregate?.avg?.amount || 0}
          />
        </Grid>
      </VStack>
      <Box layerStyle={"frame"} mb={4}>
        <HStack pb={8} justifyContent={"flex-end"}>
          <SelectAccountTypes
            data={data}
            onChange={setAccountType}
            current={accountType}
          />
        </HStack>
        <Box position={"relative"} h={"2xs"}>
          <Box h={"full"} w={"full"} position={"absolute"}>
            <NetworthHistoryChart history={data.networth_history} />
          </Box>
        </Box>
      </Box>
      <NetWorthOverview data={data} />
    </Page>
  );
};

const QUERY = gql`
  query NetWorth(
    $user_id: String!
    $account_type: String
    $start_date: timestamptz!
    $end_date: timestamptz!
  ) {
    assets: accounts_new(
      order_by: { balance: desc }
      where: { state: { _eq: "active" }, type: { _neq: "liability" } }
    ) {
      ...NetWorthOverview
    }
    liabilities: accounts_new(
      order_by: { balance: desc }
      where: { state: { _eq: "active" }, type: { _eq: "liability" } }
    ) {
      ...NetWorthOverview
    }
    assets_balance: accounts_new_aggregate(
      where: { state: { _eq: "active" }, type: { _neq: "liability" } }
    ) {
      ...NetWorthBalance
    }
    liabilities_balance: accounts_new_aggregate(
      where: { state: { _eq: "active" }, type: { _eq: "liability" } }
    ) {
      ...NetWorthBalance
    }
    networth_history(args: { user_id: $user_id, account_type: $account_type }) {
      date
      value
    }
    networth_stats(args: { user_id: $user_id }) {
      current
      gain
      financial_independence_revenue
    }
    avg_monthly_expenses: user_expenses_breakdown_history_aggregate(
      where: {
        category: { _eq: "needs" }
        month: { _gte: $start_date, _lt: $end_date }
      }
    ) {
      aggregate {
        avg {
          amount
        }
      }
    }
  }
  ${NetWorthOverview.fragments.netWorthOverview}
  ${NetWorthOverview.fragments.netWorthBalance}
`;

const NetworthStat = ({
  current,
  gain,
  startDate,
  endDate,
}: {
  current: number;
  gain: number;
  startDate: Date;
  endDate: Date;
}) => {
  const { t, i18n } = useTranslation();
  const { format } = useCurrency();
  return (
    <HStack spacing={4} layerStyle={"frame"}>
      <Stat>
        <StatLabel color={"casBlueGrey"}>{t("netWorth.self")}</StatLabel>
        <StatNumber>{format(current)}</StatNumber>
        <StatHelpText color={"casBlueGrey"}>
          {new Date().toLocaleString(i18n.language, {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </StatHelpText>
      </Stat>
      {gain !== 0 && (
        <>
          <Divider orientation={"vertical"} bg={"border"} height="50px" />
          <VStack spacing={1} alignItems={"flex-start"}>
            <HStack spacing={2}>
              {gain > 0 ? (
                <ArrowUpIcon color={"green.400"} />
              ) : (
                <ArrowDownIcon color={"red.400"} />
              )}
              <Text fontWeight={"medium"}>
                <Currency
                  value={gain || 0}
                  options={{
                    maximumFractionDigits: 0,
                  }}
                />
              </Text>
            </HStack>
            <Text fontSize={"sm"} color={"casBlueGrey"}>
              {t("netWorth.gainLabel", {
                start: startDate.toLocaleString(i18n.language, {
                  month: "short",
                }),
                end: endDate.toLocaleString(i18n.language, {
                  month: "short",
                }),
              })}
            </Text>
          </VStack>
        </>
      )}
    </HStack>
  );
};

const IndependenceRevenueStat = ({
  revenue,
  target,
}: {
  revenue: number;
  target: number;
}) => {
  const { t } = useTranslation();
  const progress = Math.round((revenue / target) * 100);
  return (
    <Box layerStyle={"frame"} w={{ base: "full", md: "max-content" }}>
      <Stat position={"relative"} pr={8} flex={1}>
        <StatLabel color={"casBlueGrey"}>
          {t("common.independenceRevenue")}
          <Box position={"absolute"} top={-2} right={-2}>
            <ContextualHelp helpText={t("glossary.independenceRevenue")} />
          </Box>
        </StatLabel>
        <StatNumber>
          <Currency value={Math.round(revenue) || 0} />
        </StatNumber>
        {revenue === 0 ? (
          <StatHelpText color={"casBlueGrey"}>
            <HStack spacing={1} alignItems={"center"}>
              <InfoIcon color={"casBlue"} />
              <Text>{t("netWorth.independenceRevenueNoData")}</Text>
            </HStack>
          </StatHelpText>
        ) : (
          <StatHelpText>
            {t("netWorth.independenceRevenueProgress", { progress })}
          </StatHelpText>
        )}
      </Stat>
    </Box>
  );
};

const NetworthHistoryChart = ({
  history,
}: {
  history: { date: number; value: number }[];
}) => {
  const { i18n } = useTranslation();
  const { format, formatCompact } = useCurrency();
  const getGradient = (ctx: ScriptableContext<"line">) => {
    const chart = ctx.chart as Chart<"line">;
    const { ctx: chartCtx, chartArea } = chart;
    if (!chartArea) {
      // This case happens on initial chart load
      return null;
    }
    const { top, bottom } = chartArea;
    const gradient = chartCtx.createLinearGradient(0, top, 0, bottom);
    gradient.addColorStop(0, "rgba(22,219,204,0.5)");
    gradient.addColorStop(0.5, "rgba(22,219,204,0.3)");
    gradient.addColorStop(1, "rgba(22,219,204,0)");
    return gradient;
  };

  const chartData: ChartData<"line", number[]> = {
    labels: history.map((d) => {
      return new Date(d.date);
    }),
    datasets: [
      {
        data: history.map((d) => d.value) || [],
        borderColor: "#16DBCC",
        tension: 0.4,
        fill: true,
        backgroundColor: getGradient as unknown as string,
        pointRadius: 0,
        pointHoverBackgroundColor: "#16DBCC",
      },
    ],
  };
  const chartConfig: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title(tooltipItems: TooltipItem<"line">[]): string | string[] | void {
            return capitalize(
              new Date(tooltipItems[0].parsed.x as number).toLocaleString(
                i18n.language,
                {
                  month: "short",
                  year: "numeric",
                },
              ),
            );
          },
          label(tooltipItem: TooltipItem<"line">) {
            let label = tooltipItem.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (tooltipItem.parsed.y !== null) {
              label += format(tooltipItem.parsed.y);
            }
            return label;
          },
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      x: {
        type: "time",
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        time: {
          unit: "month",
          tooltipFormat: "MMM yyyy",
        },
        ticks: {
          callback: function (_, index, ticks) {
            const date = new Date(ticks[index].value);
            const month = date.toLocaleString(i18n.language, {
              month: "short",
            });

            if (date.getMonth() === 0) {
              return date.getFullYear();
            }
            return capitalize(month);
          },
        },
      },
      y: {
        border: {
          display: false,
          dash: [4, 4],
        },
        grid: {
          display: true,
          drawTicks: false,
        },
        ticks: {
          callback: (value) => {
            return formatCompact(value as number);
          },
        },
      },
    },
  };

  return <Line data={chartData} options={chartConfig} />;
};

const SelectAccountTypes = ({
  data,
  current,
  onChange,
}: {
  data: NetWorthQuery;
  current: string | null;
  onChange: (type: string | null) => void;
}) => {
  const { t } = useTranslation();
  const accountTypes = useMemo(() => {
    if (!data) {
      return [];
    }
    return uniqBy(
      data?.assets
        .concat(data?.liabilities)
        .map((a) => ({
          label: t(`accountTypes.${a.type}.name`),
          value: a.type,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
      "value",
    );
  }, [data?.assets, data?.liabilities]);

  const handleSelect = (type: string | null) => {
    onChange(type);
  };

  if (!data) {
    return null;
  }

  return (
    <Select
      w={"max-content"}
      justifySelf={"flex-start"}
      size={"xs"}
      fontSize={"sm"}
      value={current || ""}
      onChange={(e) => {
        handleSelect(e.target.value || null);
      }}
    >
      <option value="">{t("netWorth.accountTypeFilterAll")}</option>
      {accountTypes.map((type) => (
        <option key={type.value} value={type.value}>
          {type.label}
        </option>
      ))}
    </Select>
  );
};

export default NetWorth;
